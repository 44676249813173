export const breedOptions = [
  { value: "beagle", label: "Beagle" },
  { value: "boxer", label: "Boxer" },
  { value: "bulldog", label: "Bulldog" },
  { value: "chihuahua", label: "Chihuahua" },
  { value: "dachshund", label: "Dachshund" },
  { value: "dalmatian", label: "Dalmatian" },
  { value: "doberman", label: "Doberman" },
  { value: "german_shepherd", label: "German Shepherd" },
  { value: "golden_retriever", label: "Golden Retriever" },
  { value: "great_dane", label: "Great Dane" },
  { value: "husky", label: "Husky" },
  { value: "labrador", label: "Labrador" },
  { value: "mastiff", label: "Mastiff" },
  { value: "poodle", label: "Poodle" },
  { value: "pug", label: "Pug" },
  { value: "rottweiler", label: "Rottweiler" },
  { value: "shih_tzu", label: "Shih Tzu" },
  { value: "spaniel", label: "Spaniel" },
  { value: "terrier", label: "Terrier" },
  { value: "yorkshire_terrier", label: "Yorkshire Terrier" },
];
